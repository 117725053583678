(function ($) {
  'use strict';

  Drupal.behaviors.fbcOneQA = {
      attach: function (context, settings) {

        // Move the form on the main landing page and animate it opening/closing
          $('#insert-webform').append( $('.webform-client-form-20710' ));
          $('.webform-client-form-20710').slideUp();

        $("#sales-button").click(function(){
          $('.webform-client-form-20710').slideDown();
          $('#sales-content').slideUp();
        });

        $('body').click(function(e) {
          if (e.target.id != 'sales-button' && $(e.target).closest('#insert-webform').length === 0) {
            $('.webform-client-form-20710').slideUp();
            $('#sales-content').slideDown();
          }
        });


        // Hide/show integration form
        // if ($('.node-type-landing-page').length()) {
        //   $('.webform-client-form-20639').slideUp();
        // }

        // $("#integration-button").click(function(){
        //   $('.webform-client-form-20639').slideDown();
        // });
        // $('body').click(function(e) {
        //   if (e.target.id != 'integration-button' && $(e.target).closest('.webform-client-form-20639').length === 0) {
        //     $('.webform-client-form-20639').slideUp();
        //     $('#sales-content').slideDown();
        //   }
        // });
      }
  };



}(jQuery));
